import Head from 'next/head'


import { useRouter } from 'next/router';
import React from 'react';




export default function() {
  const router = useRouter();

  const handleRedirect = (e)=>{
    e.preventDefault();
    router.push("/product");

  }
  return (
    <div className="p-0">
      <Head>
        <title>Domov</title>
        <meta name="description" content="výkup a predaj Europalety, Štandartnej palety, Nábytkovej palety a aj rôznych iných Atypických veľkostí" />
      
        <link rel="icon" href="/favicon.ico" />
        
      </Head>
      
     
      <section className="d-flex vw-100 text-secondary justify-content-center align-items-center flex-column">
        <h1 className="">Domov</h1>
        <div className="d-flex flex-wrap justify-content-around vw-100 bg-light p-2 border rounded m-3" style={{maxWidth:"85%"}}>
          <img src={`${process.env.NEXT_PUBLIC_CONTENTURL}/uploads/medium_fotkapriestoru_789e2fc545.jpg`}
              className="img-fluid m-3 rounded-1" style={{height:"100%",maxHeight:"23rem"}} alt="Fotka nášho skladu"
            ></img>
          <p className="d-block flex-grow-1 p-3 text-center bg-primary border rounded">
Sme profesionálna firma<strong>Meles sro</strong>, ktorá sa zaoberá <strong>výrobou, výkupom, predajom a opravou</strong> rôznych druhov <strong>paliet</strong>. Dlhoročnou praxou sme sa stretli s paletami rôznych <strong>dimenzií</strong>. Ak náhodou <strong>nenájdete</strong> v našej ponuke <strong>palety</strong>, ktoré hľadáte, <strong>nájdeme riešenie po zaslaní <a className='text-secondary-extreme' href="/general">formulára.</a></strong> 
Palety máme možnosť doviezť až pred Vaše dvere. Názov našej firmy sa zmenil, ale služby ostali rovnaké- na <strong>profesionálnej</strong> úrovni. 
Ponúkame Vám odborné rady, naše typy, naše pracovné nasadenie, rýchle jednanie, férový prístup 100% dôveru. 
Na našom webe ceny hľadať nie je potrebné, pretože každá objednávka je odlišná.  Informovať sa ohľadom cien je možné v sekcií  kúpa/predaj. Prípadne nás môžete kontaktovať telefonicky na <a href="tel: +421914222048" className="text-secondary">+421914222048</a>. 

          </p>
          <div className="w-100 p-4 ">
          <button className='btn btn-primary w-100 text-secondary-extreme' onClick={e=>handleRedirect(e)}>Naša ponuka <span>&#8594;</span></button>
          </div>
          <p className="d-block flex-grow-1 p-3 text-center bg-primary border rounded">

          </p>
          
        </div>
        <div className='vh-50 m-5'/>
      </section>

    </div>
  )
}
